* {
    .lh-box-sizing(border-box);
}

html {
    font-size: 62.5%;
}

html,
body {
    margin: 0;
    padding: 0;
    border: 0;
    width: 100%;
    //height: 100%;
}

body {
    min-height: 100%;
    color: @textcolor;
    font-family: @contentfont;
    font-size: 17px;
}

.kop {
    font-family: @titlefont;
    font-weight: 400 !important;
}
b, strong {
    //font-weight: 400 !important;
}

.btn {
    font-size: 16px;
}

.youtube {
    .lh-box-shadow(0 20px 16px -10px black);
}

.container {
    //background: #fff;
}

a {
    color: #01568D;
    text-decoration: none;

    &:link {
        text-decoration: none;
        outline: 0;
    }

    &:visited {
        text-decoration: none;
        outline: 0;
    }

    &:active {
        text-decoration: none;
        outline: 0;
    }

    &:hover {
        text-decoration: underline;
        outline: 0;
    }


    &[href $='.pdf'],
    &[href $='.xls'],
    &[href $='.xlsx'],
    &[href $='.doc'],
    &[href $='.docx'],
    &[href $='.zip'],
    &[href $='.ppt'],
    &[href $='.pptx'] {
        &:not(.no-icon){
            &:before {
                .icon;
                display: inline-block;
                margin-right: 3px;
                vertical-align: middle;
                content: "\e900";
            }
        }
    }
}

h1 {
    .kop;
    color: @blue2;
}

h2 {
    .kop;
    font-size: 20px;
}
h3 {
    .kop;
}



header {
    //background: #fff;
    .logo {
        position: relative;
        padding: 10px (@grid-gutter-width / 2);
        z-index: 5;

        img {
            max-width: 100%;
        }
    }

    .language {
        text-align: right;

        @media @small, @medium, @large {
            padding-top: 65px;
        }
        @media @phone, @xsmall {
            padding-bottom: 5px;
        }

        a {
            display: inline-block;
            margin: 0 4px;
            vertical-align: middle;

            &.flag_nl,
			&.flag_en,
			&.flag_tr,
			&.flag_ru,
			&.flag_ua,
			&.flag_pl,
			&.flag_de,
			&.flag_es,
			&.flag_fr,
			&.flag_it,
			&.flag_gr,
			&.flag_pt
			{
				background-repeat: no-repeat;
				
                background-size: cover;
                width: 25px;
                height: 17px;
			}
            &.flag_nl { background-image: url('../images/flag_nl.png'); }
            &.flag_en { background-image: url('../images/flag_uk.png'); }
            &.flag_tr { background-image: url('../images/flag_tr.png'); }
            &.flag_ru { background-image: url('../images/flag_ru.png'); }
            &.flag_ua { background-image: url('../images/flag_ua.png'); }
            &.flag_pl { background-image: url('../images/flag_pl.png'); }
            &.flag_de { background-image: url('../images/flag_de.png'); }
            &.flag_es { background-image: url('../images/flag_es.png'); }
            &.flag_fr { background-image: url('../images/flag_fr.png'); }
            &.flag_it { background-image: url('../images/flag_it.png'); }
            &.flag_gr { background-image: url('../images/flag_gr.png'); }
            &.flag_pt { background-image: url('../images/flag_pt.png'); }
        }

            .social-buttons {
                margin-left: 10px;
                @media (min-width: 768px) {
                    text-align: right;
                }
                @media (max-width: 767px) {
                    text-align: center;
                }
                a {
                    margin: 0;
                    color: @color-blue;
                    background-color: transparent;
                    border: 0px;
                    .font-size(24);
                    padding: 0;
                    vertical-align: middle;
                    &:hover,
                    &:focus {
                        text-decoration: none;
                        color: @color-orange;
                    }
                    &:after {
                        .icon;
                        display: inline-block;
                        padding: 0 3px;
                    }

                    &.btn-facebook {
                        &:after {
                            content: "\f082";
                        }
                    }
                    &.btn-linkedin {
                        &:after {
                            content: "\f08c";
                        }
                    }
                    &.btn-twitter {
                        &:after {
                            content: "\f081";
                        }
                    }
                    &.btn-youtube {
                        &:after {
                            content: "\f166";
                        }
                    }
                    &.btn-instagram {
                        &:after {
                            content: "\f16d";
                        }
                    }
                }
            }
    }

    .flexslider {
        height: 400px;
        position: relative;

        @media @phone, @xsmall {
            height: 250px;
			body.page-home & {
				height: 110px;
			}
        }

        @media @small {
            height: 325px;
        }

        .slides {
            height: 100%;
            border-bottom: 1px solid @blue2;
            li {
                height: 100%;
                background-repeat: no-repeat;
                background-size:cover;
                background-position: center center;
				body.page-home & {
					
					//background-position: top center;
				}
            }
        }
        .container {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
        }
    }

    .container-fluid {
        padding: 0 !important;
        .row {
            margin: 0;
        }
    }
    .header-quicknav {
        padding: 0;
    }
    .header-quicknav a {
        margin-left: 1px;
        margin-right: 0px;
    }
    .header-quicknav li:first-of-type a {
        margin-left: 0px;
    }

    .navbar-pmc {
        background-color: @grey;
        border: 0px;
        border-radius: 0px;
        min-height: 50px;
        color: @color-blue;
        margin-bottom: 0px;
        border-bottom: 1px solid @blue2;
        border-top: 1px solid @blue2;
        .kop;

        .container-fluid {
            padding: 0px;
        }

        .navbar-collapse {
            padding-left: 0px;
            padding-right: 0px;
        }

        .navbar-nav > li > a {
            padding-top: 15px;
            padding-left: 18px;
            padding-bottom: 15px;
            padding-right: 18px;
        }

        .navbar-nav > li + li::before {
            border-left: 1px solid @blue1;
            content: " ";
            position: absolute;
            left: 0px;
            height: 20px;
            top: 50%;
            margin-top: -10px;
        }

        .navbar-nav > .active > a,
        .navbar-nav > .active > a:hover,
        .navbar-nav > .active > a:focus {
            background-color: @blue1;
            color: @white;
        }

        .navbar-nav > li > a:hover,
        .navbar-nav > li > a:focus {
            background-color: @blue1;
            color: @white;
        }

        .navbar-nav > li > a {
            color: @white;
        }
    }



    ul.nav li.dropdown-toggle:hover > ul.dropdown-menu {
        display: block;
    }

    .dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
        background-color: @blue1;
        color: #ffffff;
    }

    .dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus {
        background-color: @blue1;
        color: #ffffff;
    }


    ul.subdropdown-menu {
        display: none;
        padding-left: 0px;
        li {
            list-style-type: none;
            padding-left: 0px;
            position: relative;
        }
    }


    ul.nav li.subdropdown-toggle {
        position: relative;
    }

    ul.nav li.subdropdown-toggle:hover > ul.subdropdown-menu {
        display: block;
        position: absolute;
        left: 100%;
        top: -1px;
        background: #fff;
        border: 1px solid rgba(0, 0, 0, 0.15);
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    }

    .subdropdown-menu > li > a:hover, .subdropdown-menu > li > a:focus {
        background-color: @color-blue;
        color: #ffffff;
    }

    .subdropdown-menu > .active > a, .subdropdown-menu > .active > a:hover, .subdropdown-menu > .active > a:focus {
        background-color: @blue1;
        color: #ffffff;
    }


    .subdropdown-menu > li > a {
        display: block;
        padding: 3px 20px;
        clear: both;
        font-weight: normal;
        line-height: 1.42857143;
        color: #333333;
        white-space: nowrap;
    }

    .subdropdown-menu > li > a:hover {
        text-decoration: none;
    }

    .nav > li > a.lang-link {
        display: inline-block;
        opacity: 0.3;
        &:hover {
            opacity: 1;
        }
    }
}

body {
    > section {
    //.content, .projecten, .news, .faq, .downloads {

        .container {
            padding-top: 30px;
            padding-bottom: 30px;
            background: #fff;
        }
    }
}


.home {
    padding-top: 30px;

    img {
        max-width: 100%;
    }

    .banner-top {
        display: block;
        line-height: 40px;
        background-color: #00557a;
        color: #ffffff;
        text-transform: uppercase;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        position: relative;

        &:after {
            position: absolute;
            border-width: 12px;
            border-style: solid;
            content: " ";
            left: 50%;
            margin-left: -6px;
            bottom: -22px;
            border-color: #00557a transparent transparent transparent;
        }
    }

    .banner-tekst {
        padding: 15px;
        background-color: #e5eef1;
    }

    .banner-button {
        padding: 10px 20px;
        border-radius: 4px;
        display: inline-block;
        background-color: #ff8700;
        color: #ffffff;
        text-transform: uppercase;

        &:hover {
            text-decoration: none;
            background-color: #00557a;
        }
    }
}

.zerniq-wysiwyg {
    h1 {
        font-size: 24px;
    }

    h2 {
        color: @blue1;
        font-size: 20px;
    }
    h3 {
        margin: 0 0 3px 0;
    }
    p + h3 {
        padding-top: 10px;
    }
    a {
        color: @color-orange;
        &:hover,
        &:active {
            color: @color-blue;
        }
    }

    p, ul, ol {
        font-weight: 300
    }

    table img {
        //width: 100%;
    }

    img {
        max-width: 100%;
    }
}


.flexslider {
    box-shadow: none !important;
    border-radius: 0px !important;
    border: 0px !important;
    margin-bottom: 0px !important;
}

.flex-direction-nav a {
    color: #009ada;
}


.hid3 table, .hid4 table {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.hid3 table td, .hid4 table td {
    color: #000000;
}

.hid3 table > tbody > tr:nth-child(odd) > td,
.hid3 table > tbody > tr:nth-child(odd) > th {
    background-color: #f4f4f4;
}
.hid4 table > tbody > tr:nth-child(odd) > td,
.hid4 table > tbody > tr:nth-child(odd) > th {
    background-color: #f4f4f4;
}

.hid3 table td, .hid4 table td {
    padding: 2px;
}

.newslink {
    margin-bottom: 20px;

    &:nth-child(4n+1){
        clear: both;
    }

    a {
        text-decoration: none;
        color: @textcolor;

        h3 {
            color: @blue1;
        }

        &:hover {
            color: @textcolor;
            h3 {
                color: @blue2;
            }
        }
    }
}


.subnav-head {
    display: block;
    line-height: 40px;
    background-color: #00557a;
    color: #ffffff;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    position: relative;

    &:after {
        position: absolute;
        border-width: 12px;
        border-style: solid;
        content: " ";
        left: 50%;
        margin-left: -6px;
        bottom: -22px;
        border-color: #00557a transparent transparent transparent;
        z-index: 5;
    }
}


.subnav-box.list-group {
    background-color: #009ada;
    padding: 0px 15px;

    .list-group-item {
        background-color: #009ada;
        border: 0px solid #009ada;
        color: #ffffff;
        padding-top: 5px;
        padding-bottom: 5px;
        border-top: 5px solid #009ada;
        border-bottom: 5px solid #009ada;

        &:hover, &.active {
            background-color: #ff8700;
            border-radius: 4px;
        }
    }
}


.menu {
    padding: 15px;
}

.list-group-item,
:first-child.list-group-item,
:last-child.list-group-item {
    border-radius: 0px;
}

.list-group-item:hover {
    background-color: #8C6D25;
}

.active.list-group-item,
.active.list-group-item:hover,
.active.list-group-item:focus {
    background-color: #6095c1;
    border-color: #6095c1;
}

.list-group .list-group {
    margin-bottom: 0px;
    padding-left: 0px;
}

.list-group .list-group .list-group-item {
    padding-left: 30px;
}

.list-group + .list-group {
    margin-top: 30px;
}


.form-horizontal .control-label {
    text-align: left;
}

#gmap {
    width: 100%;
    height: 300px;
    margin-bottom: 30px;
}

#maplist {
    list-style-type: none;
    padding: 0px;
}

#maplist li:before {
    content: "\e117";
    position: relative;
    top: 1px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 10px;
    color: #E6CC0E;
}

#maplist li.active:before {
    content: "\e118";
}

#maplist ul {
    list-style-type: none;
    padding: 0px;
    margin-left: 30px;
}

#maplist .filelist {

}

#maplist .filelist li:before {
    display: inline-block;
    font: normal normal normal 16px/1 FontAwesome;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin-right: 10px;
    color: #666666;
    content: "\f016";
}

#maplist .filelist li.pdf:before {
    content: "\f1c1";
}

#maplist .filelist li.doc:before,
#maplist .filelist li.docx:before {
    content: "\f1c2";
}

#maplist .filelist li.xls:before,
#maplist .filelist li.xlsx:before {
    content: "\f1c3";
}

#maplist .filelist li.ppt:before,
#maplist .filelist li.pptx:before {
    content: "\f1c4";
}


footer {
    background-color: @blue1;
    .doormat {
        color: #fff;
        padding-top: 20px;
        padding-bottom: 20px;
        a {
            color: #fff;
            &:hover,
            &:focus {
                //color: @color-blue;
            }
        }
    }
    .footnote {
        background-color: @grey;
        .copyright {
            .kop;
            padding-top: 15px;
            padding-bottom: 15px;
            color: #ffffff;
            line-height: 34px;
            .social-buttons {
                @media (min-width: 768px) {
                    text-align: right;
                }
                @media (max-width: 767px) {
                    text-align: center;
                }
                a {
                    color: #ffffff;
                    background-color: transparent;
                    border: 0px;
                    .font-size(24);

                    &:hover,
                    &:focus {
                        text-decoration: none;
                        color: @blue1;
                    }
                    &:after {
                        .icon;
                        display: inline-block;
                        padding: 0 3px;
                    }

                    &.btn-facebook {
                        &:after {
                            content: "\f082";
                        }
                    }
                    &.btn-linkedin {
                        &:after {
                            content: "\f08c";
                        }
                    }
                    &.btn-twitter {
                        &:after {
                            content: "\f081";
                        }
                    }
                    &.btn-youtube {
                        &:after {
                            content: "\f166";
                        }
                    }
                    &.btn-instagram {
                        &:after {
                            content: "\f16d";
                        }
                    }
                }
            }
        }


    }
}

#content {
    padding-bottom: 30px;
}


hr {
    border-top-color: #c9ddf1;
}

h2, .h2,
h3, .h3 {
    color: #303030;
    margin-top: 0px;
}

h1, .h1 {
    font-size: 28px;
    margin-top: 0px;
}
h2, .h2 {
    font-size: 24px;
}
h3, .h3 {
    font-size: 18px;
}


a {
    color: #01568D;
}

a:hover {
    color: #666666;
}

.text-uppercase {
    text-transform: uppercase;
}

.btn {
    font-weight: 300;
    border-radius: 0px;
}

.img-responsive {
    max-width: 100%;
}

.btn-light {
    background-color: @color-blue;
    color: #ffffff;
}

.btn-light:focus, .btn-light:hover, .btn-light:active {
    background-color: @color-orange;
    color: #ffffff;
}

.btn-dark {
    background-color: @color-blue;
    color: #ffffff;
}

.btn-dark:focus, .btn-dark:hover, .btn-dark:active {
    background-color: @color-blue;
    color: #ffffff;
}

.btn-orange {
    background-color: @color-orange;
    color: #ffffff;
}

.btn-orange:focus, .btn-orange:hover, .btn-orange:active {
    background-color:  darken(@color-orange, 15%);
    color: #ffffff;
}

.btn-social-icon {
    border: 0px;
}


img.nieuwsfoto {
    float: right;
    margin-bottom: 30px;
    margin-left: 30px;
}
.mailchimp {
    width: 100%;
    text-align: right;
}
.banners {
    margin-top: 30px;
}
.sponsors {
    margin-bottom: 30px;
}

.sponsors img {
    width: 100%;
}

.sponsors > div {
    height: 110px;
    line-height: 110px;
}

.partners {
    margin: 20px 0;
    .td {
        height: 110px;
        line-height: 110px;
        img {
            display: inline-block;
        }
    }
}

.click-box {
    cursor: pointer;
}




.row.no-gutters {
    @media @large {
        margin-right: -15px;
        margin-left: -15px;
    }
    @media  @medium {
        margin-right: -10px;
        margin-left: -10px;
    }
    @media @phone, @xsmall, @small {
        margin-right: 0;
        margin-left: 0;
    }

    > [class^="col-"],
    > [class*=" col-"] {
        padding-right: 0;
        padding-left: 0;
    }
}

.navbar {
    @media @medium, @large {
        .navbar-nav {
            display: inline-block;
            float: none;
            vertical-align: top;
        }

        .navbar-collapse {
            text-align: center;
        }
    }
}


@media @phone, @xsmall, @small {
    .menu {
        position: absolute;
        left: -600px;
        z-index: 5;
        width: 100%;
        padding: 0px;
        opacity: 0;
        .lh-transition(visibility 0s 0.3s, opacity 0.3s, left 0.3s);

        &.show {
            left: 0px;
            opacity: 1;
            background-color: #ffffff;
        }
    }



    .navbar-pmc .container-fluid {
        padding: 0px 10px;
    }

    .navbar-nav > li > .dropdown-menu {
        width: 100%;
    }

    ul.nav li.dropdown-toggle > ul.dropdown-menu {
        display: block;
    }

    ul.nav li.subdropdown-toggle > ul.subdropdown-menu {
        display: block;
    }

    .dropdown-menu {
        position: relative;
    }

    .dropdown-menu > li a {
        text-align: right;
    }

    .navbar-pmc .navbar-collapse {
        padding-left: 10px;
        padding-right: 10px;
    }
    .navbar-pmc .navbar-nav > li + li::before {
        border-left: 0px;
    }

    .sponsors > div {
        height: auto;
        line-height: auto;
        margin-bottom: 30px;
    }

    body {
        font-size: 16px;
    }

}

#mixcontainer {
    .mix {
        display: none;
        margin-bottom: 20px;
        @media @large {
            height: 215px;
        }
        @media  @medium {
            height: 185px;
        }
        @media @phone, @xsmall, @small {
            height: auto;
        }
    }
}

.panel {
    .panel-heading {
        h4 {
            a {
                display: block;

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }
}

.list-unstyled .list-unstyled {
    margin-left: 30px;
}

#dealer-map {
    @media @medium, @large {
        height: 600px;
    }
}

#dealer-map .infobaox {
    position: relative;
    background-color: #fff;
    padding: 10px;
    margin-left: -50%;
    margin-top: -110%;
    margin-right: 50%;
    width: 220px;
    &::after {
        content: " ";
        position: absolute;
        bottom: -40px;
        margin-left: -40px;
        left: 50%;
        border: 40px solid #fff;
        border-color: #fff transparent transparent  transparent;
        border-width: 40px 40px 0px 40px;
    }
}

.back-button {
    margin-bottom: 15px;
}

#countdown {
	position: absolute;
	left: 0;
	width: 100%;
	
	bottom: 0;
	@media @phone, @xsmall {
		bottom: auto;
	}
	background-color: fade(@black, 50%);
	font-size: 34px;
	line-height: 54px;
	text-align: center;
	color: #ffffff;
	z-index: 40;
	span {
		@media @phone, @xsmall {
			display: none;
		}
	}
	i {
		@media @phone, @xsmall {
			&:after {
				content: ':';
			}
		}
	}

}

#navigation {
	position: absolute;
	left: 0;
	width: 100%;
	
	bottom: 0;
	@media @phone, @xsmall {
		bottom: auto;
	}
	font-size: 24px;
	line-height: 54px;
	text-align: center;
	color: #ffffff;
	z-index: 40;
	a {
		display: inline-block;
		padding: 0 30px;
		background-color: #00a7e3;
		color: #fff;
		&:hover {
			background-color: #565455;
			text-decoration: none;
		}
		line-height: 44px;
		margin-bottom: 5px;
	}

}

.dealer-shop {
	.nav-list {
		.no-bullets;
		> li {
			a {
				display: block;
				padding: 3px 10px;
			}
			ul {
				.no-bullets;
				padding-left: 20px;
				border-bottom: 1px solid #000;
				&:last-child {
					border-bottom: 0;
				}
			}
		}
		li.active {
			> a {
				border-left: 1px solid #000;
				border-bottom: 1px solid #000;
				background-color: #f6f6f6;
			}
		}
	}
	.form-control:not(textarea) {
		height: 36px;
		&[type=number] {
			text-align: right;
		}
	}
	
	.kit-list {
		.kit-product + .kit-product {
			border-top: 1px solid #efdfef;
			padding-top: 4px;
			margin-top: 3px;
		}
	}
	
	.producten-lijst {
		margin: 20px 0;
		.single-product + .single-product {
			border-top: 1px solid #efdfef;
			padding-top: 4px;
			margin-top: 3px;
		}
	}
	.cart-info-widget {
		margin: 20px 0;
	}
	img {
		max-width: 100%;
		height: auto;
	}
	.producten-lijst img,
	.kit-product img {
		max-width: 100%;
		height: auto;
		max-height: 100px;
	}
	#order-gegevens, #order-controle {
		margin: 30px 0;
	}
}

.mb-2 {
    margin-bottom: 20px;
}