// --------------------------------------------------
// Global settings
// --------------------------------------------------

// Colors
@black:                    #000;
@white:                    #FFF;
@color-purple: #8871b1;
@color-pink: #ed6ea7;
@color-lightblue: #CBE5F4;
@color-blue: #007DC7;
@color-orange: #F19523;
@bg-color: #f8f8f8;


@blue1: #0281c4;
@blue2: #034174;
@grey: #434343;
@textcolor: #444;

// Breakpoints

/* Extra small vertical devices (phones, less than 480px) */
@phone:                     ~"only screen and (max-width: @{screen-xs-min})";

/* Extra small devices (horizontal phones, less than 768px) */
@xsmall:                    ~"only screen and (min-width: @{screen-xs-min}) and (max-width: @{screen-xs-max})";

/* Small devices (tablets, 768px and up) */
@small:                     ~"only screen and (min-width: @{screen-sm-min}) and (max-width: @{screen-sm-max})";

/* Medium devices (desktops, 992px and up) */
@medium:                    ~"only screen and (min-width: @{screen-md-min}) and (max-width: @{screen-md-max})";

/* Large devices (large desktops, 1200px and up) */
@large:                     ~"only screen and (min-width: @{screen-lg-min})";

/* Higher pixel density screens */
@retina:                    ~"only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min-resolution: 144dpi)";


// Typography
@contentfont:                'Roboto', Arial, "Helvetica Neue", Helvetica, sans-serif;
@titlefont:                   'Roboto Condensed', @contentfont;
