// external fonts
//@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300italic,300,400italic,400);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:400);
@import url(https://fonts.googleapis.com/css?family=Roboto:300italic,300,400);

// local fonts
@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.eot');
    src: url('../fonts/icomoon.eot?#iefix') format('embedded-opentype'),
        url('../fonts/icomoon.woff2') format('woff2'),
        url('../fonts/icomoon.ttf') format('truetype'),
        url('../fonts/icomoon.woff') format('woff'),
        url('../fonts/icomoon.svg#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\f00d";
}
.icon-remove:before {
  content: "\f00d";
}
.icon-copy:before {
  content: "\f0c5";
}
.icon-times:before {
  content: "\f00d";
}
.icon-refresh:before {
  content: "\f021";
}
.icon-shopping-cart:before {
  content: "\f07a";
}
.icon-twitter-square:before {
  content: "\f081";
}
.icon-facebook-square:before {
  content: "\f082";
}
.icon-linkedin-square:before {
  content: "\f08c";
}
.icon-youtube-square:before {
  content: "\f166";
}
.icon-instagram:before {
  content: "\f16d";
}
.icon-file-text:before {
  content: "\e900";
} 